import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar/TopBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DesktopNavBar from "./NavBar/DesktopNavBar";
import { AuthContext } from "../../context/Auth";
import PageLoading from "../../component/PageLoading";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
  },
  dashboardWrapper: {
    display: "flex",
    height: "88vh",
    padding: "0 0 20px 0",
  },
  sideBarWrapper: {
    background: "#fff",
    margin: "10px 0 0 10px",
    overflow: "auto",

    width: "16rem",
    minWidth: "190px",
    padding: "2rem 3rem 2rem 2rem",
    borderRadius: "10px",
  },
  childrenWrapper: {
    background: "#fff",
    margin: "11px 0 0 14px",
    borderRadius: "10px",
    width: "100%",
    height: "87vh",
    overflow: "auto",
    "@media(max-width:600px)": {
      margin: "11px 0 0 0",
      height: "90vh",
    },
  },

  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // background: "linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(250, 213, 168) 40%, rgb(249, 191, 121) 60%, rgb(247, 168, 75) 80%, rgb(245, 146, 27) 100%)",
    backgroundColor: "#fff",
    paddingTop: 70,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.background.dark1,
  },
  content: {
    flex: "1 1 auto",
    // height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "10px 50px 20px ",
    minHeight: "91vh",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px 20px ",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 20px ",
    },
  },
  reportButton: {
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.60)",
    width: "181px",
    height: "50px",
    padding: "10px 30px",
    margin: "10px 10px 0px",
  },
  width: {
    display: "flex",
    flexDirection: "column",
    width: "78%",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "81%",
      // padding:"0px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const matches = useMediaQuery("(min-width:960px)");
  const location = useLocation();
  const history = useHistory();

  const { isLoading } = useContext(AuthContext);
  const isButtonEnabled =
    location.pathname === "/technician/techniciannamelist/view";
  const isButtonEnabledReconcile = location.pathname === "/reconcilation";
  const isButtonEnabledReconcileReport =
    location.pathname === "/reconcilation/view";

  const isSendReportPage = location.pathname === "/custom/sendReport";

  return (
    <Box className={classes.root}>
      {isLoading && <PageLoading />}
      <TopBar />

      <Box className={classes.dashboardWrapper}>
        {matches ? (
          <Box className={classes.sideBarWrapper}>
            <DesktopNavBar />
          </Box>
        ) : (
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        )}{" "}
        <Box style={{}} className={classes.width}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // padding: "5px 15px",
              marginTop: "2px",
            }}
          >
            {isButtonEnabled && (
              <button className={classes.reportButton}>Download Report</button>
            )}

            {isSendReportPage && (
              <button
                className={classes.reportButton}
                onClick={() => {
                  history.push("/custom/sendReport/AllSchedule");
                }}
              >
                See All Schedules
              </button>
            )}
          </Box>
          <Box className={classes.childrenWrapper}>{children}</Box>
        </Box>
      </Box>

    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;

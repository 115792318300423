import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dailogOpen: {
    borderRadius: "25px",
    "& h5": {
      color: "black",
      fontSize: "17px",
      paddingBottom: "1rem",
    },
  },
}));

export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
  type,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [fileType, setFileType] = useState('csv');
  const changeFileType = (event) => {
    setFileType(event.target.value);
  };
  return (
    <Dialog
      open={open}
      className={classes.dailogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h2">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "400",
              color: "#000",
            }}
          >
            {desc}
          </Typography>
        </DialogContentText>
        {type === 'downloadType' && (<>
          <FormControl component="fieldset" >
            <RadioGroup
              value={fileType}
              onChange={changeFileType}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" />
            </RadioGroup>
          </FormControl>
        </>)}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose();
            // confirmationHandler()
          }}
          style={{ width: !matches && "100%" }}
        >
          No
        </Button>
        <Button
          variant="containedPrimary"
          onClick={() => confirmationHandler(fileType)}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Container, makeStyles } from "@material-ui/core";
import {
  sideBarItems,
  DashboardIcon,
  WarehouseIcon,
  DivisionIcon,
  PartsIcon,
  InventoryIcon,
  LogshipmentIcon,
  TechnicianIcon,
  ReconcileIcon,
  CustomIcon,
  LogoutIcon,
} from "../../../utils";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { postAPIdata } from "src/APIconfig/services";
import { AuthContext } from "../../../context/Auth";
const useStyles = makeStyles((theme) => ({
  optionContainer: {
    display: "flex",
    width: "230px",
    gap: "1rem",
    margin: "0px",
    alignItems: "center",
    justifyContent: "flex-start",
    // borderRadius: "10px",
    height: "45px",
    fontSize: "16px",
    cursor: "pointer",
  },
}));

export default function DesktopNavBar() {
  const [roleMappings, setroleMappings] = useState({
    SUPERADMIN: [
      "/dashboard",
      "/division",
      "/warehouse",
      "/parts",
      "/inventory",
      "/activityLogs",
      "/logshipment",
      "/technician",
      "/reconcilation",
      "/custom",
    ],
    ADMIN: [
      "/dashboard",
      "/division",
      "/warehouse",
      "/parts",
      "/inventory",
      "/activityLogs",
      "/logshipment",
      "/technician",
      "/reconcilation",
      "/custom",
    ],
    TECHNICIAN: ["/inventory", "/logshipment", "/reconcilation"],
    DIVISION: ["/warehouse", "/inventory"],
    CLIENT: ["/inventory"],
    // Add more roles as needed
  });

  const getFilteredSidebarItems = (role) => {
    const allowedLinks = roleMappings[role] || [];
    return sideBarItems.filter((item) => allowedLinks.includes(item.href));
  };
  const router = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const confirmationHandler = async () => {
    let res = await postAPIdata({
      endPoint: "adminLogout",
      headers: { token: sessionStorage.getItem("token") },
    });
    if (res.responseCode === 200) {
      setOpen(false);
      sessionStorage.clear();
      history.push("/login");
    }
  };

  const auth = useContext(AuthContext);
  return (
    <div>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Are you sure you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      {getFilteredSidebarItems(auth?.Role).map((item, i) => {
        const titleMatches = router.pathname.includes(item.href);
        const Icon = item.icon;
        const allowedLinks = roleMappings[auth?.Role] || [];

        return (
          <Container
            key={i}
            className={classes.optionContainer}
            style={{
              background: titleMatches ? "#FF9F07" : "#fff",
              color: titleMatches ? "#fff" : "rgba(0, 0, 0, 0.87)",
              borderBottom: "1px solid #e5e5e5",
            }}
            onClick={() => {
              if (allowedLinks.includes(item.href)) {
                history.push(item.href);
              } else {
                console.log(
                  `Link ${item.href} does not match the allowed links`
                );
              }
            }}
          >
            <Icon fill={titleMatches ? "#fff" : "rgba(0, 0, 0, 0.60)"} />
            <Box
              style={{
                background: titleMatches ? "#FF9F07" : "#fff",
                color: titleMatches ? "#fff" : "#000",
                fontWeight: "300",
              }}
            >
              {item.title}
            </Box>
          </Container>
        );
      })}
      <>
        <Container
          className={classes.optionContainer}
          style={{ background: "#fff", color: "rgba(0, 0, 0, 0.87)" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <LogoutIcon fill={"rgba(0, 0, 0, 0.60)"} />
          <Box
            style={{
              background: "#fff",
              color: "#000",
              fontWeight: "300",
            }}
          >
            {"Logout"}
          </Box>
        </Container>
      </>
    </div>
  );
}

export const baseurl = "https://node.orbitalcustoms.com"; //live
// export const baseurl = "https://node-orbitalinventory.mobiloitte.io"; //staging
// export const baseurl = "http://localhost:2036/api/v1"; //staging

let user = `${baseurl}/api/v1/user`;
let admin = `${baseurl}/api/v1/admin`;
let parts = `${baseurl}/api/v1/parts`;
let logshipment = `${baseurl}/api/v1/logshipment`;
let inventory = `${baseurl}/api/v1/inventory`;
let reconcile = `${baseurl}/api/v1/reconcile`;
const apiConfig = {
  globalSearch: `${admin}/globalSearch`,

  getOrbitalUserDetails: `${admin}/getOrbitalUserDetails`,
  adminLogout: `${admin}/adminLogout`,
  getPackingSlip: `${admin}/getPackingSlip`,
  login: `${admin}/adminLogin`,
  listActivityLogs: `${admin}/listActivityLogs`,
  socialLogin: `${user}/socialLogin`,
  forgotPassword: `${admin}/forgotPassword`,
  verifyOTP: `${admin}/verifyOTP`,
  resendOTP: `${admin}/resendOTP`,
  resetPassword: `${admin}/resetPassword`,
  updateProfile: `${admin}/updateProfile`,
  getProfile: `${admin}/getProfile`,
  adminDashboard: `${admin}/adminDashboard`,
  registeredUserGraph: `${admin}/registeredUserGraph`,

  //file
  uploadFile: `${admin}/uploadFile`,

  //division section
  allDivisionList: `${admin}/allDivisionList`,
  addUserDivision: `${admin}/addUserDivision`,
  editUserDivision: `${admin}/editUserDivision`,
  editDivision: `${admin}/editDivision`,
  addDivision: `${admin}/addDivision`,
  divisionManagement: `${admin}/divisionManagement`,
  deleteDivision: `${admin}/deleteDivision`,
  divisionDetailAssociateClient: `${admin}/divisionDetailAssociateClient`,
  userListDivision: `${admin}/userListDivision`,
  addDivisionSite: `${admin}/addDivisionSite`,
  viewAllSites: `${admin}/viewAllSites`,
  viewAllDivisions: `${admin}/viewAllDivisions`,

  //parts
  partList: `${parts}/partList`,
  viewParts: `${parts}/viewParts`,
  updateParts: `${parts}/updateParts`,
  deletePart: `${parts}/deleteParts`,
  addPart: `${parts}/addParts`,

  //client
  editClient: `${admin}/editClient`,
  editUserClient: `${admin}/editUserClient`,
  addclient: `${admin}/addclient`,
  clientList: `${admin}/clientList`,
  addUserClient: `${admin}/addUserClient`,
  userListClient: `${admin}/userListClient`,
  addParts: `${parts}/addParts`,

  //inventory
  inventoryPartList: `${inventory}/inventoryPartList`,
  inventoryList: `${inventory}/inventoryList`,
  myInventoryList: `${inventory}/myInventoryList`,
  inventoryTechnicianList: `${inventory}/inventoryTechnicianList`,
  inventoryHistoryList: `${inventory}/inventoryHistoryList`,
  viewInventoryHistory: `${inventory}/viewInventoryHistory`,
  viewInventory: `${inventory}/viewInventory`,
  updateInventory: `${inventory}/updateInventory`,
  addInventory: `${inventory}/addInventory`,
  deleteInventory: `${inventory}/deleteInventory`,

  //warehouse section
  addSite: `${admin}/addSite`,
  viewSite: `${admin}/viewSite`,
  editSite: `${admin}/editSite`,
  listSite: `${admin}/listSite`,

  //logshipment
  findAllshipmeninventory: `${logshipment}/findAllshipmeninventory`,
  createTransferInventory: `${logshipment}/createTransferInventory`,
  viewShipmentInventory: `${logshipment}/viewShipmentInventory`,
  updateShipmentInventory: `${logshipment}/updateShipmentInventory`,

  //technician
  technicianList: `${admin}/listTechnicianList`,

  //ReconcileInventory

  reconcileList: `${reconcile}/getReconcileInventoryList`,
  addReconcile: `${reconcile}/addReconcileInventory`,
  viewReconcileInventoryHistory: `${reconcile}/viewReconcileInventoryHistory`,

  //download xlsx file
  xlsxDownload: `${admin}/xlsxDownload`,
  customReport: `${admin}/customReport`,
};

export default apiConfig;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AuthContext } from "../../context/Auth";
import PageLoading from "../../component/PageLoading";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "100vh",
    overflowX: "auto",
    background: theme.palette.background.dark1,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  box: {
    background: theme.palette.background.lightblack,

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "50px",
    },
  },
  centro: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  opener: {
    // "@media(min-width:1280px)":{
    //   display:"none"
    // },
  },
  headBox: {},
  logBox: {
    padding: "0 0 20px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 60px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0",
    },
  },
  loginBox: {
    background: "url('./images/background.png')",
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
      },
    },
  },
  gridView: {
    "@media(max-width:600px)": {
      display: "none",
    },
  },
  imgBox: {
    margin: "0",
    "@media(max-width:600px)": {
      maxWidth: "120px",
      margin: "auto",
    },
  },
  leftImg: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
      marginTop: "10px",
    },
  },
  PdTp: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "42vw",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainbox: {
    boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(42px)",
    background:
      "linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(250, 213, 168) 40%, rgb(249, 191, 121) 60%, rgb(247, 168, 75) 80%, rgb(245, 146, 27) 100%)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  content: {
    // height: "100vh",

    overflowX: "auto",
    // background: "#000",
    backgroundColor: "#fff",
    color: "#fff",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1280px)");
  const history = useHistory();
  const { isLoading } = useContext(AuthContext);

  return (
    <>
      {isLoading && <PageLoading />}
      <Box className={classes.headBox}></Box>
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          background: "#fff",
          gap: "10vw",
        }}
      >
        <Grid item className={classes.PdTp}>
          <Container>
            <Box
              className={classes.logBox}
              style={{
                margin: matches ? "0 0 10px 40px" : "0",
              }}
            >
              <Box className={classes.imgBox}>
                <img src="/images/orbitalLogo.png" height="70" />
              </Box>
              <Box className={classes.content} style={{ marginLeft: "0" }}>
                {children}
              </Box>
            </Box>
          </Container>
        </Grid>
        <Grid
          style={{
            display: !matches ? "none" : "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img src="/images/loginImage.png" height="77%" /> */}
          <img src="images/loginsvg.svg" alt="Login" />
        </Grid>
      </Grid>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;

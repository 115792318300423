import axios from "axios";
import ApiConfig from "./ApiConfig";
import { toast } from "react-toastify";

export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    // responseCode's wrong spelling coming from BE as responceCode
    if (res.data.responceCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });

    // responseCode's wrong spelling coming from BE as responceCode
    if (res.data.responseCode === 200) {
      toast.success(res?.data?.message)
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const getAPIdataSearch = async ({ params, data, endPoint, headers, cancelTokenSourceRef }) => {

  try {
    let res;
    let cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    res = await axios({
      url: ApiConfig[endPoint],
      method: "GET",
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource.token,
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          // toast.success(res?.data?.message)
          return res?.data;
        }
        if (res?.data?.responseCode === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 401) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 205) {
          // toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was canceled, so no need to handle the error
          return;
        }
        toast.error(error.response.data.error);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const getAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: ApiConfig[endPoint],
      method: "GET",
      params: params,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          // toast.success(res?.data?.message)
          return res?.data;
        }
        if (res?.data?.responseCode === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 401) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 205) {
          // toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {

        toast.error(error.response.data.error);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: ApiConfig[endPoint],
      method: "PUT",
      params: params,
      data: data,
      headers: {
        ...headers
      },
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          toast.success(res?.data?.message);
          return res?.data;
        }
        if (res?.data?.responseCode === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const postAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: ApiConfig[endPoint],
      method: "POST",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          if (res?.data) {
            // toast.success(res?.data?.message)
            return res?.data;
          } else {
            // toast.success(res?.data?.message)
            return res?.data;
          }
        }
        if (res?.data?.responseCode === 201) {
          if (res?.data) {
            // toast.success(res?.data?.message)
            return res?.data;
          } else {
            // toast.success(res?.data?.message)
            return res?.data;
          }
        }
        if (res?.data?.responseCode === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.data.status === 401) {
          toast.error(error.response.data.error);
          return false;
        }
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const deleteAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: ApiConfig[endPoint],
      method: "DELETE",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          toast.success(res?.data?.message);
          return res?.data;
        }
        if (res?.data?.responseCode === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.responseCode === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};


import React, { useState, useContext } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box, Container, Drawer, Switch, makeStyles } from "@material-ui/core";
import { AuthContext } from "../../../context/Auth";
import { Menu, Security } from "@material-ui/icons";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { LogoutIcon, sideBarItems } from "../../../utils";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { postAPIdata } from "src/APIconfig/services";
import SearchBoxField from "src/component/SearchBoxField";

const useStyles = makeStyles((theme) => ({
  desktopTopBar: {
    padding: "10px 2.2rem",
    margin: "0",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between"
  },
  mobileTobBar: {
    display: "flex",
    height: "3rem",
    padding: "0 10px ",
    background: "#FAA43C",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },
  mobileTitle: {
    fontSize: "18px",
    textTransform: "capitalize",
    fontWeight: "600",
  },
  mobileDrawer: {
    width: "60vw",
    maxWidth: "260px",
    padding: "4rem 4rem 0 25px",
  },
  optionsWrapper: {},
  option: {
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.87)",
    gap: "10px",
    fontSize: "17px",
    padding: "14px 15px",
    borderRadius: "10px",
  },
  dpWrraper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    margin: "0 0 2rem 0",
  },
  dp: {
    height: "60px",
    background: "#dcdcdc",
    width: "60px",
    borderRadius: "50%",
  },
  name: {
    color: "#000",
    fontSize: "19px",
    fontWeight: "500",
  },
}));



function TopBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(min-width:960px)");
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [roleMappings, setroleMappings] = useState({
    SUPERADMIN: ["/dashboard",
      "/division",
      "/warehouse",
      "/parts",
      "/inventory",
      "/activityLogs",
      "/logshipment",
      "/technician",
      "/reconcilation",
      "/custom",],
    ADMIN: ["/dashboard",
      "/division",
      "/warehouse",
      "/parts",
      "/inventory",
      "/activityLogs",
      "/logshipment",
      "/technician",
      "/reconcilation",
      "/custom",],
    TECHNICIAN: ["/inventory", "/logshipment", "/reconcilation",],
    DIVISION: ["/warehouse", "/inventory"],
    CLIENT: ["/inventory"],
    // Add more roles as needed
  });
  const getFilteredSidebarItems = (role) => {
    const allowedLinks = roleMappings[role] || [];
    return sideBarItems.filter((item) => allowedLinks.includes(item.href));
  };
  const handleMobileBackClick = () => {
    switch (location?.pathname) {
      case "/division/create":
        history.push("/division");
        break;
      case "/inventory/detail":
        history.push("/inventory");
        break;
      case "/warehouse/viewSite":
        history.push("/warehouse");
        break;
      case "/inventory/history":
        history.push("/inventory");
        break;
      case "/inventory/history/view":
        history.push("/inventory/history");
        break;
      case "/logshipment/detail":
        history.push("/logshipment");
        break;
      case "/warehouse/section":
        history.push("/warehouse");
        break;
      case "/parts/create":
        history.push("/parts");
        break;
      case "/inventory/create":
        history.push("/inventory");
        break;
      case "/division/view":
        history.push("/division");
        break;
      case "/logshipment/create":
        history.push("/logshipment");
        break;
      case "/technician/techniciannamelist":
        history.push("/technician");
        break;
      case "/technician/techniciannamelist/view":
        history.push("/technician/techniciannamelist");
        break;
      case "/reconcilation/view":
        history.push("/reconcilation");
        break;
      case "/custom/sendReport":
        history.push("/custom");
        break;
      case "/custom/sendReport/AllSchedule":
        history.push("/custom/sendReport");
        break;
      case "/custom/sendReport/AllSchedule/UpdateReport":
        history.push("/custom/sendReport/AllSchedule");
        break;
      case "/reconcilation/addReport":
        history.push("/reconcilation");
        break;
      default:
        break;
    }
  };

  const handleHeader = () => {
    if (location.pathname) {
      const breakRoute = location?.pathname?.split("/");
      let title = "Dashbboard";
      switch (location?.pathname) {
        case "/division/create":
          title = "Add Division";
          break;
        case "/inventory/detail":
          title = "Inventory Detail";
          break;
        case "/warehouse/viewSite":
          title = "Site Details";
          break;
        case "/inventory/history":
          title = "Inventory History";
          break;
        case "/inventory/history/view":
          title = "View Report";
          break;
        case "/logshipment/detail":
          title = "Logshipment Details";
          break;
        case "/warehouse/section":
          title = "Site";
          break;
        case "/parts/create":
          title = "Part";
          break;
        case "/inventory/create":
          title = "Inventory";
          break;
        case "/division/view":
          title = "Division Detail";
          break;
        case "/logshipment/create":
          title = "Logshipment";
          break;
        case "/technician/techniciannamelist":
          title = "Technician Name";
          break;
        case "/technician":
          title = "Technician ";
          break;
        case "/technician/techniciannamelist/view":
          title = "Technician Details";
          break;
        case "/reconcilation":
          title = "Reconcilation Inventory";
          break;
        case "/reconcilation/view":
          title = "View Reconcilation Report";
          break;

        case "/custom/sendReport":
          title = "Custom Report";
          break;

        case "/custom/sendReport/AllSchedule":
          title = "All Schedules";
          break;

        case "/custom/sendReport/AllSchedule/UpdateReport":
          title = "Update Report";
          break;

        case "/reconcilation/addReport":
          title = "Add Report";
          break;
        default:
          break;
      }

      if (breakRoute.length > 2) {
        let noheader = ['/technician/techniciannamelist']
        if (!noheader.includes(location?.pathname)) {
          return (
            <Box
              style={{ display: "flex", textAlign: "center", padding: "15px" }}
            >
              <img src="/images/chevron.svg" onClick={handleMobileBackClick} />
              <span
                style={{
                  textTransform: "capitalize",
                  fontWeight: "400",
                  fontSize: "18px",
                  margin: "auto",
                }}
              >
                {title}
              </span>
            </Box>
          );
        }
      } else {
        return (
          <Box className={classes.mobileTobBar}>
            <Menu
              style={{ fontWeight: "500", fontSize: "24px" }}
              onClick={() => setDrawerOpen(true)}
            />
            <span className={classes.mobileTitle}>
              {location.pathname.replace("/", "")}
            </span>
            <span></span>
          </Box>
        );
      }
    }
  };

  const confirmationHandler = async () => {
    let res = await postAPIdata({
      endPoint: "adminLogout",
      headers: { token: sessionStorage.getItem("token") },
    });
    if (res.responseCode === 200) {
      sessionStorage.removeItem("token");
      history.push("/login");
    }
  };

  return (
    <>
      {!matches ? (
        handleHeader()
      ) : (
        <>
          <Box className={classes.desktopTopBar}>
            <img src="/images/orbitalLogo.png" height="60" />
            {location.pathname !== "/search/results" && (<>
              <SearchBoxField />
            </>)}
            <Box></Box>
          </Box>
        </>
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <Drawer
        anchor="left"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        variant="temporary"
      >
        <Box className={classes.mobileDrawer}>
          <Box className={classes.optionsWrapper}>
            {getFilteredSidebarItems(auth?.Role).map((item, i) => {
              const Icon = item.icon;
              const titleMatches =
                location.pathname === "/" + item.title.toLocaleLowerCase();
              const allowedLinks = roleMappings[auth?.Role] || [];
              return (
                <Box
                  className={classes.option}
                  onClick={() => {
                    if (allowedLinks.includes(item.href)) {
                      history.push(item.href);
                    } else {
                      console.log(
                        `Link ${item.href} does not match the allowed links`
                      );
                    }
                  }}
                  style={{
                    background: titleMatches ? "#FF9F07" : "#fff",
                    color: titleMatches ? "#fff" : "rgba(0, 0, 0, 0.87)",
                  }}
                  key={i}
                >
                  <span>
                    <Icon
                      fill={titleMatches ? "#fff" : "rgba(0, 0, 0, 0.60)"}
                    />
                  </span>
                  <span>{item?.title}</span>
                </Box>
              );
            })}
            <Container
              className={classes.option}
              style={{ background: "#fff", color: "rgba(0, 0, 0, 0.87)" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <LogoutIcon fill={"rgba(0, 0, 0, 0.60)"} />
              <Box
                style={{ background: "#fff", color: "#000", fontWeight: "300" }}
              >
                {"Logout"}
              </Box>
            </Container>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
export default TopBar;
